<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6">{{ new Date().getFullYear() - 1 }} - {{ new Date().getFullYear() }} © Kio88.</div>
        <div class="col-sm-6">
          <!-- <div class="text-sm-end d-none d-sm-block">Design & Develop by Themesbrand</div> -->
        </div>
      </div>
    </div>
  </footer>
  <!-- end footer -->
</template>
